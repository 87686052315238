<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
        <h6 class="mb-0">Device battery details</h6>
      </div>
    </template>
    <div>
      <div class="form-group">
        <label>Set a new battery:</label>
        <select
          name="select-action"
          class="form-control form-control-solid form-control-lg"
          aria-describedby="enter-act-feedback"
          v-model="selected_battery"
        >
          <option :value="-1" disabled>Select an action</option>
          <option
            :value="obj.id"
            v-for="(obj, i) in batteries_available"
            :key="i"
          >
            {{ obj.name }}
          </option>
        </select>
        <b-form-invalid-feedback id="enter-act-feedback">
          Select a battery if you changed it
        </b-form-invalid-feedback>
        <span class="form-text text-muted">Select a battery if you changed it</span>
      </div>
      <b-button
        variant="success"
        @click="resetBattery"
        :disabled="btnUpdate || selected_battery === -1"
      >
        <b-spinner small v-show="btnUpdate"></b-spinner>
        Update
      </b-button>
      <br />
      <br />
      <b-row no-gutters class="battery-grid-container">
        <b-col md="4" class="battery-image-col">
          <b-card-img :src="imageSrc" class="rounded-0 battery-image"></b-card-img>
        </b-col>
        <b-col class="battery-info-grid">
          <div class="grid-wrapper">
            <b-card bg-variant="secondary" text-variant="black" class="battery-card text-center">
              <b-icon class="h1" icon="lightning-charge-fill" variant="Default" v-b-tooltip.hover title="Remaining battery"></b-icon>
              <b-card-text class="battery-text">{{ battery_summary }} remaining</b-card-text>
            </b-card>
            <b-card bg-variant="secondary" text-variant="black" class="battery-card text-center">
              <b-icon class="h1" icon="hourglass-split" variant="Default" v-b-tooltip.hover title="Remaining days and total days running"></b-icon>
              <b-card-text class="battery-text">
                {{ remainingDays }}d left<br />
                {{ runningDays }}d running<br />
                {{ extraLabel }}
              </b-card-text>
            </b-card>
            <b-card bg-variant="secondary" text-variant="black" class="battery-card text-center">
              <b-icon class="h2" icon="calendar3" variant="Default" v-b-tooltip.hover title="Start date of battery replacement"></b-icon>
              <b-card-text class="battery-text">Installed on {{ startDate }}</b-card-text>
            </b-card>
            <b-card bg-variant="secondary" text-variant="black" class="battery-card text-center">
              <b-icon class="h2" icon="reply-all-fill" variant="Default" v-b-tooltip.hover title="Replace the battery by this date"></b-icon>
              <b-card-text class="battery-text">Replace by {{ endDate }}</b-card-text>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </div>
    <br />
    <template v-if="statusLoaded == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"></b-spinner>
      </div>
    </template>
    <template v-else>
      <div>
        <b-button v-b-toggle.collapse-1b variant="primary">Show battery history</b-button>
        <b-collapse id="collapse-1b" class="mt-2">
          <b-card>
            <b-table
              striped
              hover
              :items="markers"
              @row-clicked="rowClicked"
              :tbody-tr-class="rowClassCallback"
              :fields="tableFields"
            >
              <template v-slot:cell(created_at)="data">
                {{ formatDate(data.item.created_at) }}
              </template>
            </b-table>
          </b-card>
        </b-collapse>
      </div>
    </template>
  </b-card>
</template>

<style scoped>
.battery-grid-container {
  width: 100%;
}

.battery-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.battery-image {
  max-width: 100%;
  height: auto;
}

.battery-info-grid {
  padding: 0.5rem;
}

.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;
}

.battery-card {
  min-height: 120px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.battery-text {
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .grid-wrapper {
    grid-template-columns: 1fr;
  }
  
  .battery-card {
    min-height: 100px;
  }
  
  .battery-image {
    max-width: 80%;
  }
}

@media (max-width: 576px) {
  .battery-text {
    font-size: 0.6875rem;
  }
}
</style>

<script>
import { STORE_TRACKER_LOCATIONS, GET_BATTERY_DETAILS, GET_BATTERIES, POST_BATTERY_RESET } from "@/core/services/store/devices.module";
import { mapGetters } from "vuex";

export default {
  name: "suitch-battery-widget",
  props: {
    findMyType: { type: String, default: "" },
    device: null,
    limitBatteryDate: { type: String, default: "" },
    showName: { type: Boolean, default: false },
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
      console.log("Unsubscribed from store");
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setMessage" && this.device) {
        if (state.realtime.last_message.type === "location" && state.realtime.last_message.token === this.device.token) {
          this.downloadData();
        }
      } else if (mutation.type === "storeBatteryInfo") {
        this.calculateRunningDates();
      } else if (mutation.type === STORE_TRACKER_LOCATIONS && this.findMyType === "tracker") {
        if (this.$store.state.devices.trackerLocations.length > 0) {
          this.limitBatteryDate = this.$store.state.devices.trackerLocations[0].created_at;
          console.log("Last time viewed:", this.limitBatteryDate);
        }
        this.calculateRunningDates();
        this.statusLoaded = true;
      }
    });
    this.downloadData();
  },
  data() {
    return {
      unsubscribe: null,
      imageSrc: "media/devices/batteries/cr2477.png",
      current_device: { connection_type: -1 },
      statusLoaded: null,
      markers: [],
      markersLoaded: false,
      tableFields: [
        { key: "battery_type_name", label: "Type" },
        { key: "created_at", label: "Updated At", formatter: "formatDate" },
      ],
      btnUpdate: false,
      remainingDays: 0,
      runningDays: 0,
      extraDays: 0,
      extraLabel: ".",
      startDate: "",
      endDate: "",
      battery_summary: "",
      batteries_available: [],
      selected_battery: -1,
      battery_cycle_date: "",
    };
  },
  methods: {
    rowClassCallback(item, type) {
      return type === "row" && this.isSelected(item) ? "map-selected-row" : "";
    },
    rowClicked(clickedItem) {
      this.selectedMarker = this.selectedMarker === clickedItem ? null : clickedItem;
    },
    formatDate(dateString) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      try {
        const date = new Date(dateString);
        if (isNaN(date)) throw new Error("Invalid date");
        return new Intl.DateTimeFormat(undefined, options).format(date);
      } catch (err) {
        console.error("Error formatting date:", err);
        return "Unknown";
      }
    },
    async resetBattery() {
      if (this.selected_battery === -1) return;
      this.btnUpdate = true;
      try {
        await this.$store.dispatch(POST_BATTERY_RESET, {
          token: this.device.token,
          selectedBattery: this.selected_battery,
        });
        this.downloadData(); // Refresh data after reset
      } catch (error) {
        console.error("Error resetting battery:", error);
      } finally {
        this.btnUpdate = false;
      }
    },
    async updateBatteryInfo() {
      if (!this.device) return;

      try {
        const data = await this.$store.dispatch(GET_BATTERY_DETAILS, { device_token: this.device.token });
        this.markers = data || [];
        
        if (this.markers.length === 0) {
          this.battery_summary = "No data";
          this.startDate = "N/A";
          this.endDate = "N/A";
          return;
        }

        const highest = this.markers.reduce((acc, current) =>
          acc.battery_cycle_id > current.battery_cycle_id ? acc : current,
          { battery_cycle_id: -Infinity }
        );
        this.startDate = this.formatDate(highest.created_at);
        this.battery_cycle_date = highest.created_at;

        const actual_battery = this.batteries_available.find(item => item.id === highest.battery_id);
        if (!actual_battery) {
          console.warn("Battery type not found for ID:", highest.battery_id);
          this.battery_summary = "Unknown battery";
          return;
        }

        // Update battery image
        const batteryImages = {
          "C2": "media/devices/batteries/cr17345.png",
          "CR2477": "media/devices/batteries/cr2477.png",
          "CR2032": "media/devices/batteries/cr2032.png",
          "CR232": "media/devices/batteries/cr2032.png",
          "CR2032 Steren": "media/devices/batteries/cr2032.png",
          "CR2032 Energizer": "media/devices/batteries/cr2032.png",
          "CR2450": "media/devices/batteries/cr2477.png",
          "18650": "media/devices/batteries/18650.png",
          "CR2450H": "media/devices/batteries/cr2450h.png",
          "CR2477N": "media/devices/batteries/cr2477n.png",
          "CR2477H": "media/devices/batteries/cr2477h.png",
        };
        this.imageSrc = batteryImages[actual_battery.name] || "media/devices/batteries/cr2477.png";

        // Calculate end date and remaining days
        const startDateObj = new Date(highest.created_at);
        const endDateObj = new Date(startDateObj);
        endDateObj.setDate(startDateObj.getDate() + actual_battery.days);
        this.endDate = this.formatDate(endDateObj);

        const currentDate = new Date();
        const differenceInMs = endDateObj - currentDate;
        this.remainingDays = Math.max(0, Math.floor(differenceInMs / (1000 * 60 * 60 * 24)));
        const percentage = Math.floor((100 * this.remainingDays) / actual_battery.days);
        this.battery_summary = `${percentage}%`;

        this.calculateRunningDates();
      } catch (error) {
        console.error("Error updating battery info:", error);
        this.battery_summary = "Error loading data";
      } finally {
        this.statusLoaded = true;
        this.btnUpdate = false;
      }
    },
    calculateRunningDates() {
      if (!this.battery_cycle_date) return;

      const startDate = new Date(this.battery_cycle_date);
      const endDate = new Date(this.endDate);
      const compareDate = this.limitBatteryDate ? new Date(this.limitBatteryDate) : new Date();
      
      this.runningDays = Math.floor((compareDate - startDate) / (1000 * 60 * 60 * 24));
      const supposedTime = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
      this.extraDays = this.runningDays - supposedTime;

      this.extraLabel = this.extraDays > 0 ? `Extra ${this.extraDays} days` : ".";
    },
    async downloadData() {
      if (!this.device) return;

      this.btnUpdate = true;
      this.statusLoaded = null;
      this.markers = [];
      this.markersLoaded = false;

      try {
        // Fetch batteries if not already loaded
        if (this.$store.state.devices.battery_info.length === 0) {
          const batteries = await this.$store.dispatch(GET_BATTERIES);
          this.batteries_available = batteries.filter(item => item.device_type === this.device.version);
        } else {
          this.batteries_available = this.$store.state.devices.battery_info.filter(
            item => item.device_type === this.device.version
          );
        }

        // Fetch battery details and update UI
        await this.updateBatteryInfo();
      } catch (error) {
        console.error("Error downloading data:", error);
        this.statusLoaded = true;
        this.battery_summary = "Failed to load";
      } finally {
        this.btnUpdate = false;
      }
    },
    isSelected(item) {
      return this.selectedMarker === item;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  watch: {
    device(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.downloadData();
      }
    },
  },
};
</script>